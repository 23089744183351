<template>
  <div>
    <div class="content">
      <div v-show="isShow === true">
        <div class="card">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus">添加</el-button>
          <el-button type="primary" plain icon="el-icon-upload2">导出</el-button>
          <div class="line"></div>
          <!-- <el-select placeholder="请选择公司" style="width: 200px;margin-right:10px;">
            <el-option label="区域一" :value="11"></el-option>
          </el-select>-->
          <el-input placeholder="请输入手机号码" style="width: 200px;margin-right:10px;"></el-input>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button type="primary" @click="handleAdd(0)" style="margin-left:10px;">搜索</el-button>
        </div>
        <div class="table">
          <el-table :data="list" stripe>
            <el-table-column align="center" prop="name" label="公司名称" width="150"></el-table-column>
            <el-table-column align="center" prop="admin_name" label="管理员姓名" width="150"></el-table-column>
            <el-table-column align="center" prop="admin_account" label="管理员手机号" width="150"></el-table-column>
            <el-table-column
              align="center"
              prop="use_status_text"
              label="使用状态"
              column-key="use_status_text"
              :filters="[
        {text: '免费使用', value: '免费使用'},
        {text: '正式合作', value: '正式合作'},
        ]"
              :filter-method="filterHandler"
              width="150"
            ></el-table-column>
            <el-table-column align="center" prop="child_shop" label="门店数量" width="150"></el-table-column>
            <el-table-column align="center" prop="child_accessory" label="设备数量" width="150"></el-table-column>
            <el-table-column align="center" prop="connection_agent" label="代理商" width="250"></el-table-column>
            <el-table-column align="center" prop="create_time" label="添加时间" width="200"></el-table-column>
            <el-table-column align="center" prop="audit_text" label="审核状态" width="150"></el-table-column>
            <el-table-column align="center" prop="audit_time" label="审核时间" width="200"></el-table-column>
            <el-table-column align="center" prop="end_time" label="到期时间" width="150"></el-table-column>
            <el-table-column align="center" prop="end_day" label="到期天数" width="150"></el-table-column>
            <el-table-column align="center" fixed="right" prop="operation" label="操作" width="200">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="审核" :value="0" v-if="scope.row.audit_text === '待审核'"></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="about" v-show="isShow === false">
      <BusinessAdd @recapture="handleAgain()" />
    </div>
    <el-dialog
      :visible.sync="auditVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="() => {auditVisible = false}"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="原因说明">
          <el-radio-group v-model="form.audit" @change="handleAudit">
            <el-radio label="20">审核通过</el-radio>
            <el-radio label="30">审核不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商家类型" v-if="auditType === '20'">
          <el-radio-group v-model="form.use_status">
            <el-radio label="10">免费使用商家</el-radio>
            <el-radio label="20">正式合作商家</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因说明" v-if="auditType === '30'">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="form.audit_remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="() => {auditVisible = false}">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import BusinessAdd from "../businessList/add/index.vue";
import { getList, merchantAudit } from "@/api/businessManage/business";
export default {
  data() {
    return {
      isShow: true,
      list: [],
      auditVisible: false,
      auditType: "20",
      form: {
        audit: "20",
      },
      hierarchy_id: "",
    };
  },
  components: {
    BusinessAdd,
  },
  created() {
    this.fetchData();
  },
  methods: {
    /*
     *  列表数据
     */
    fetchData() {
      const data = {
        type_id: 20,
      };
      getList(data).then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
        }
      });
    },
    handleAdd() {
      this.isShow = false;
    },
    /*
     *  重新加载列表
     */
    handleAgain() {
      this.isShow = true;
      this.fetchData();
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    /*
     *  原因说明动态处理
     */
    handleAudit(e) {
      console.log(e);
      this.auditType = e;
    },
    /*
     *   操作
     */
    handleChange(e, current) {
      this.hierarchy_id = current.hierarchy_id;
      if (e === 0) {
        this.auditVisible = true;
      }
    },
    /*
     *  提交审核
     */
    onSubmit() {
      const data = {
        hierarchy_id: this.hierarchy_id,
        audit: this.form.audit,
        use_status: this.form.use_status,
      };
      if (this.form.audit === "30") {
        data.audit_remark = this.form.audit_remark;
      }
      merchantAudit(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "提交审核成功",
            type: "success",
          });
          this.auditVisible = false;
          this.fetchData();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}
</style>
