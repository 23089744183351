<template>
  <div>
    <div class="content">
      <div class="newly">
        <el-page-header @back="goBack" content="添加商家信息"></el-page-header>
      </div>
    </div>
    <div class="proBox">
      <div class="progress">
        <div class="icon">
          <div class="num">1</div>
          <div :class="secondPro === true ? 'proLine' : 'noLine'"></div>
          <div :class="secondPro === true ? 'num': 'noNum'">2</div>
          <div :class="threePro === true ? 'proLine' : 'noLine'"></div>
          <div :class="threePro === true ? 'num': 'noNum'">3</div>
        </div>
        <div class="tips">
          <div class="tipsText" style="text-align: left;">商家信息</div>
          <div class="tipsText" style="text-align: center;">添加门店及设备</div>
          <div class="tipsText" style="text-align: right;">入驻完成</div>
        </div>
        <div class="tips">
          <el-button
            type="primary"
            style="margin: 0 auto 0 auto;"
            plain
            @click="handleSkip"
            v-if="threePro === false"
          >跳过添加</el-button>
        </div>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="ruleForm" v-if="secondPro === false">
      <div class="newForm">
        <div class="title">
          <div class="span"></div>公司信息
        </div>
        <div class="cutLine"></div>
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="选择代理商" :label-width="formLabelWidth" prop="hierarchy_pid">
                <el-select v-model="form.hierarchy_pid" placeholder="请选择代理商" style="width: 375px">
                  <template v-for="(item, index) in hierarchy_list">
                    <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司性质" :label-width="formLabelWidth">
                <el-select
                  v-model="form.company_type_id"
                  placeholder="请选择公司性质"
                  style="width: 375px"
                >
                  <el-option label="国有企业" value="1"></el-option>
                  <el-option label="私营企业" value="2"></el-option>
                  <el-option label="港资企业" value="3"></el-option>
                  <el-option label="外企" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属区域" :label-width="formLabelWidth">
                <el-row>
                  <el-col :span="8">
                    <el-select v-model="form.provinceId" placeholder="请选择省" @change="handleProvie">
                      <template v-for="(item, index) in provinceList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.cityId" placeholder="请选择市" @change="handleCity">
                      <template v-for="(item, index) in cityList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.areaId" placeholder="请选择区" @change="handleArea">
                      <template v-for="(item, index) in areaList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人姓名" :label-width="formLabelWidth" prop="legal_person_name">
                <el-input v-model="form.legal_person_name" placeholder="请输入法人姓名" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司名称" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off" placeholder="请选择公司名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址" :label-width="formLabelWidth">
                <el-input v-model="form.addr" autocomplete="off" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号码" :label-width="formLabelWidth" prop="phone_num">
                <el-input v-model="form.phone_num" placeholder="请输入手机号码" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="门店规模" :label-width="formLabelWidth">
                <el-input v-model="form.store_scale" autocomplete="off" placeholder="请输入旗下门店数量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司简称" :label-width="formLabelWidth">
                <el-input v-model="form.short_name" autocomplete="off" placeholder="请输入公司简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注信息" :label-width="formLabelWidth">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  autocomplete="off"
                  placeholder="请输入备注信息"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="身份证认证" :label-width="formLabelWidth" required>
                <div style="display: flex;flex-direction: row;">
                  <el-upload
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handlePicpositiveSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="positive_img" :src="positive_img" class="avatar" />
                    <img v-else :src="positive" class="avatar" />
                  </el-upload>
                  <el-upload
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handlePicnegativeSuccess"
                    :before-upload="beforeAvatarUpload"
                    style="margin-left:20px"
                  >
                    <img v-if="negative_img" :src="negative_img" class="avatar" />
                    <img v-else :src="negative" class="avatar" />
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="公司LOGO" :label-width="formLabelWidth">
                <div style="display: flex;flex-direction: row;">
                  <el-upload
                    class="avatar-uploader"
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handleLogoUrlSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="logo_img" :src="logo_img" class="avatarOther" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="公司营业执照" :label-width="formLabelWidth">
                <div style="display: flex;flex-direction: row;">
                  <el-upload
                    class="avatar-uploader"
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handleLicenseUrlSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="license_img" :src="license_img" class="avatarOther" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="newForm">
        <div class="title">
          <div class="span"></div>代理商管理账号
        </div>
        <div class="cutLine"></div>
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="管理员姓名" :label-width="formLabelWidth" prop="admin_name">
                <el-input v-model="form.admin_name" autocomplete="off" placeholder="请输入管理员姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="管理员手机" :label-width="formLabelWidth" prop="admin_account">
                <el-input v-model="form.admin_account" autocomplete="off" placeholder="请输入管理员手机"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="margin-bottom: 20px">
            <el-button type="primary" @click="submit('ruleForm')" style="margin-bottom: 20px">下一步</el-button>
          </div>
        </div>
      </div>
    </el-form>
    <div v-if="secondPro === true && threePro === false">
      <div class="card">
        <el-button type="primary" @click="handleAdd" icon="el-icon-plus">添加</el-button>
      </div>
      <div class="table">
        <el-table :data="tableData" stripe>
          <el-table-column align="center" prop="name" label="门店名称"></el-table-column>
          <el-table-column align="center" prop="name" label="门店地址"></el-table-column>
          <el-table-column align="center" prop="name" label="店长名称"></el-table-column>
          <el-table-column align="center" prop="name" label="店长手机号"></el-table-column>
          <el-table-column align="center" prop="name" label="设备数量"></el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="secondPro === true && threePro === true" class="auditSuccess">
      <img :src="auditSuccess" class="auditImg" />
      <div class="audit_text">
        入驻商家的公司名称为
        <span>{{agentName}}</span> 资料已提交审核阶段，请您耐心等待原因说明！
      </div>
      <el-button plain style="margin: 10px auto 0 47%;" type="primary" @click="goBack()">返回商家列表</el-button>
    </div>
    <el-dialog
      :visible.sync="storeVisible"
      width="50%"
      title="添加门店"
      :destroy-on-close="true"
      :before-close="() => {storeVisible = false}"
    >
      <div>
        <el-form :model="shopForm" :rules="storeRules" ref="shopForm">
          <div class="title">
            <div class="span"></div>门店信息
          </div>
          <el-form-item label="门店名称" :label-width="shopFormLabelWidth" prop="name">
            <el-input
              v-model="shopForm.name"
              autocomplete="off"
              placeholder="请输入门店名称"
              style="width: 300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店简称" :label-width="shopFormLabelWidth">
            <el-input
              v-model="shopForm.shop_name"
              autocomplete="off"
              placeholder="请输入门店简称"
              style="width: 300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属区域" :label-width="shopFormLabelWidth" :required="true">
            <el-row>
              <el-col :span="8">
                <el-select v-model="shopForm.provinceId" placeholder="请选择省" @change="handleProvie">
                  <template v-for="(item, index) in provinceList">
                    <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                  </template>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="shopForm.cityId" placeholder="请选择市" @change="handleCity">
                  <template v-for="(item, index) in cityList">
                    <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                  </template>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="shopForm.areaId" placeholder="请选择区" @change="handleArea">
                  <template v-for="(item, index) in areaList">
                    <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                  </template>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="详细地址" :label-width="shopFormLabelWidth" prop="addr">
            <el-input
              v-model="shopForm.addr"
              autocomplete="off"
              placeholder="请输入详细地址"
              style="width: 300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店LOGO" :label-width="shopFormLabelWidth">
            <div style="display: flex;flex-direction: row;">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                :data="{
                      directory: 'AGENT_INFO'
                    }"
                :headers="{
                        authorization: token
                    }"
                :show-file-list="false"
                :on-success="handleStoreLogoUrlSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="storeLogo_img" :src="storeLogo_img" class="avatarOther" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="营业执照" :label-width="shopFormLabelWidth">
            <div style="display: flex;flex-direction: row;">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                :data="{
                      directory: 'AGENT_INFO'
                    }"
                :headers="{
                        authorization: token
                    }"
                :show-file-list="false"
                :on-success="handleStoreLicenseUrlSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="storeLicense_img" :src="storeLicense_img" class="avatarOther" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <div class="title">
            <div class="span"></div>设备信息
          </div>
          <div v-for="(item, index) in accessory" :key="index">
            <el-row>
              <el-col :span="8">
                <el-form-item label="设备类型" :label-width="shopFormLabelWidth" :required="true">
                  <el-select v-model="item.ha_type" placeholder="请选择设备类型">
                    <el-option label="收银机" value="10"></el-option>
                    <el-option label="扫码枪" value="20"></el-option>
                    <el-option label="盘点枪" value="30"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="SN" :label-width="shopFormLabelWidth" :required="true">
                  <el-input v-model="item.ha_sn" autocomplete="off" placeholder="请输入SN"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-button
                  type="primary"
                  style="margin-left: 20px"
                  v-if="index === 0"
                  @click="handleAccessAdd(index)"
                >添加</el-button>
                <el-button
                  type="primary"
                  style="margin-left: 20px"
                  v-if="index !== 0"
                  @click="handleAccessDel(index)"
                >删除</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="title">
            <div class="span"></div>店长账号
          </div>
          <el-form-item label="店长姓名" :label-width="shopFormLabelWidth" prop="admin_name">
            <el-input
              v-model="shopForm.admin_name"
              autocomplete="off"
              placeholder="请输入店长姓名"
              style="width: 300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="店长手机号" :label-width="shopFormLabelWidth" prop="admin_account">
            <el-input
              v-model="shopForm.admin_account"
              autocomplete="off"
              placeholder="请输入店长手机号"
              style="width: 300px;"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align:right">
          <el-button type="primary" @click="storeSubmit('shopForm')">保存</el-button>
          <el-button @click="() => {storeVisible = false}">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import negative from "../../../../../assets/images/formAbout/icon_negative.png";
import positive from "../../../../../assets/images/formAbout/icon_positive.png";
import auditSuccess from "../../../../../assets/images/formAbout/auditSuccess.png";
import { getSelectList, getRegionList } from "@/api/agentManage/index";
import { add, shopList, shopAdd } from "@/api/businessManage/business";
import Cookies from "js-cookie";
import storage from "good-storage";

export default {
  data() {
    return {
      form: {},
      formLabelWidth: "150px",
      shopFormLabelWidth: "100px",
      imageUrl: "",
      positive: positive,
      negative: negative,
      auditSuccess: auditSuccess,
      provinceList: [],
      cityList: [],
      areaList: [],
      license_url: "", // 营业执照
      license_img: "",
      hierarchy_list: [],
      icon_positive: "", // 身份证正面
      positive_img: "",
      icon_negative: "", // 身份证反面
      negative_img: "",
      logo_url: "", // 公司LOGO
      logo_img: "",
      region_id: "", // 区域id
      storeVisible: false,
      rules: {
        hierarchy_pid: [
          { required: true, message: "请选择代理商", trigger: "change" },
        ],
        legal_person_name: [
          { required: true, message: "请输入代理商姓名", trigger: "blur" },
        ],
        phone_num: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        agent_type: [
          { required: true, message: "请选择商家类型", trigger: "change" },
        ],
        admin_name: [
          { required: true, message: "请输入管理员姓名", trigger: "blur" },
        ],
        admin_account: [
          { required: true, message: "请输入管理员手机号", trigger: "blur" },
        ],
      },
      storeRules: {
        name: [
          {
            required: true,
            message: "请输入门店名称",
            trigger: "blur",
          },
        ],
        addr: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        admin_name: [
          {
            required: true,
            message: "请输入店长姓名",
            trigger: "blur",
          },
        ],
        admin_account: [
          {
            required: true,
            message: "请输入店长手机号",
            trigger: "blur",
          },
        ],
      },
      token: "",
      secondPro: false,
      threePro: false,
      shopForm: {},
      accessory: [
        {
          ha_type: "",
          ha_sn: "",
        },
      ],
      storeLogo_url: "",
      storeLogo_img: "",
      storeLicense_url: "",
      storeLicense_img: "",
      storeRegion_id: "",
      hierarchy_pid: 25,
      tableData: [],
      agentName: "",
    };
  },
  created() {
    this.getSelectList();
    this.getRegionList();
    this.token = storage.session.get("token") || Cookies.get("token");
  },
  methods: {
    /*
     *  关联商家数据
     */
    getSelectList() {
      const data = {
        type: 1,
      };
      getSelectList(data).then((res) => {
        if (res.code === 1) {
          if (res.data && res.data.length !== 0) {
            this.hierarchy_list = res.data;
          }
        }
      });
    },
    /*
     *  省市区下拉数据
     */
    getRegionList() {
      const data = {
        pid: 1,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.provinceList = res.data;
        }
      });
    },
    /*
     *   获取省id
     */
    handleProvie(id) {
      this.region_id = id;
      this.storeRegion_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.cityList = res.data;
        }
      });
    },
    /*
     *  获取城市id
     */
    handleCity(id) {
      this.region_id = id;
      this.storeRegion_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.areaList = res.data;
        }
      });
    },
    /*
     *   获取区id
     */
    handleArea(id) {
      this.region_id = id;
      this.storeRegion_id = id;
    },
    /*
     *  身份证正面
     */
    handlePicpositiveSuccess(res, file) {
      this.icon_positive = res.data.data;
      this.positive_img = URL.createObjectURL(file.raw);
    },
    /*
     *  身份证反面
     */
    handlePicnegativeSuccess(res, file) {
      this.icon_negative = res.data.data;
      this.negative_img = URL.createObjectURL(file.raw);
    },
    /*
     *  公司LOGO
     */
    handleLogoUrlSuccess(res, file) {
      this.logo_url = res.data.data;
      this.logo_img = URL.createObjectURL(file.raw);
    },
    /*
     *  营业执照图片上传
     */
    handleLicenseUrlSuccess(res, file) {
      this.license_url = res.data.data;
      this.license_img = URL.createObjectURL(file.raw);
    },
    /*
     *  商家信息添加
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = { ...this.form };
          delete form.areaId;
          delete form.cityId;
          delete form.provinceId;

          form.legal_person_img_url =
            this.icon_positive + "," + this.icon_negative || "";
          form.logo = this.logo_url;
          form.license_url = this.license_url; // 营业执照
          add(form).then((res) => {
            if (res.code === 1) {
              this.secondPro = true;
              this.hierarchy_pid = res.data.hierarchy_id;
              this.agentName = res.data.name;
              this.fetchShop(res.data.hierarchy_id);
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          this.$message({
            message: "请检查必填项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    /*
     *   门店信息添加
     */
    storeSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = { ...this.shopForm };
          delete form.areaId;
          delete form.cityId;
          delete form.provinceId;

          form.logo = this.storeLogo_url;
          form.license_url = this.storeLicense_url;
          form.hierarchy_pid = this.hierarchy_pid;
          form.accessory = this.accessory;
          shopAdd(form).then((res) => {
            if (res.code === 1) {
              this.threePro = true;
              this.$message({
                message: "添加门店成功！",
                type: "success",
              });
            }
          });
        } else {
          this.$message({
            message: "请检查必填项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("recapture");
    },
    /*
     * 查询门店信息
     */
    fetchShop(id) {
      const data = {
        hierarchy_id: id,
      };
      shopList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
        }
      });
    },
    /*
     *  门店Logo图片上传
     */
    handleStoreLogoUrlSuccess(res, file) {
      this.storeLogo_url = res.data.data;
      this.storeLogo_img = URL.createObjectURL(file.raw);
    },
    /*
     *  门店营业执照图片上传
     */
    handleStoreLicenseUrlSuccess(res, file) {
      this.storeLicense_url = res.data.data;
      this.storeLicense_img = URL.createObjectURL(file.raw);
    },
    /*
     *  添加门店
     */
    handleAdd() {
      this.storeVisible = true;
    },
    /*
     *  删除设备信息
     */
    handleAccessDel(index) {
      this.accessory = this.accessory.filter(
        (item, indexs) => indexs !== index
      );
    },
    /*
     *  新增设备信息
     */
    handleAccessAdd() {
      this.accessory.push({
        ha_type: "",
        ha_sn: "",
      });
    },
    /*
     *  跳过添加
     */
    handleSkip() {
      this.secondPro = true;
      this.threePro = true;
    },
    beforeAvatarUpload() {},
  },
};
</script>

<style scoped  lang="less">
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 16px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .newly {
  padding: 20px;
  width: 100%;
}
/deep/ .proBox {
  padding-top: 60px;
  width: 100%;
  margin-top: 10px;
  background: white;
}
/deep/ .progress {
  margin: auto;
  width: 700px;
  height: 200px;
  .icon {
    display: flex;
    flex-direction: row;

    .num {
      width: 70px;
      height: 70px;
      background: #5493f5;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
      color: white;
    }

    .proLine {
      margin: 35px 0 0 0;
      width: 245px;
      height: 2px;
      background: #5493f5;
    }

    .noLine {
      margin: 35px 0 0 0;
      width: 245px;
      height: 2px;
      background: #c4cfdf;
    }

    .noNum {
      width: 70px;
      height: 70px;
      background: #ddd;
      border: 1px solid #c4cfdf;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
      color: #333333;
    }
  }

  .tips {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    .tipsText {
      width: 300px;
    }
  }
}

/deep/ .cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}
/deep/ .form {
  width: 90%;
  margin-top: 30px;
  padding-left: 20px;
}
/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/ .auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  background-color: white;

  /deep/ .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  /deep/ .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}

/deep/ .nextPro {
  width: 141px;
  height: 40px;
  background: #5493f5;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  color: white;
}
</style>